<template>
  <div class="app_con">
    <div class="p-20">
      <!-- <div class="m-b-20">
        <el-row>
          <el-col :span="6">
            <el-button
              type="success"
              @click="
                dialogVisible = true;
                newType = 'add';
              "
              >新增</el-button
            >

            <el-button
              type="primary"
              @click="handlerEdit"
              :disabled="selecTionArr.length !== 1"
              >编辑</el-button
            >
            <el-button
              type="danger"
              @click="handlerDelete"
              :disabled="selecTionArr.length <= 0"
              >删除</el-button
            >
          </el-col>
          <el-col :span="18">
            <div class="search">
              <el-input
                placeholder="班组名称"
                v-model="filtterData.singleCondition"
                class="searchInput"
              >
                <template slot="prepend">班组名称</template>
              </el-input>
            </div>
          </el-col>
        </el-row>
      </div> -->
      <div class="table-wrap">
        <el-table
          :data="dataList"
          border
          style="width: 100%"
          v-loading="loading"
          row-key="id"
          @selection-change="getSelection($event)"
        >
          <el-table-column type="selection" width="55"> </el-table-column>
        </el-table>
      </div>

      <el-pagination
        background
        :current-page.sync="pageInfo.pageNum"
        :page-size="pageInfo.pageSize"
        :total="pageInfo.total"
        :layout="'sizes, prev, pager, next, jumper'"
        @size-change="onSizeChange"
        @current-change="onPageChange"
      />
    </div>

    <!-- <el-dialog
      title="班组人员"
      :visible.sync="showTeamDetail"
      width="800px"
      @close="showTeamDetail = false"
    >
      <span slot="footer">
        <el-button type="primary">确定</el-button>
      </span>
    </el-dialog> -->
  </div>
</template>

<script>
import api from "@/api/common";
export default {
  data() {
    // let self = this;
    return {
      dataList: [],
      pageInfo: {
        currentPage: 1,
        pageCount: 1,
        pageSize: 10,
        total: 0,
        apiName: "/ticketNeedOrder",
      },
      form: {},
    };
  },
  created() {
    this.getDataList();
  },
  watch: {
    // filtterData: {
    //   handler() {
    //     this.getList();
    //   },
    //   // immediate: true,
    //   deep: true, //开启深度监听
    // },
  },
  mounted() {},

  filters:{},

  methods: {
    async getDataList() {
      try {
        let res = await api.common.commonGet(this.pageInfo);
        this.dataList = res.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    getSelection(e) {
      this.selecTionArr = e;
    },
    async onSizeChange(e) {
      this.pageInfo.pageSize = e;
      this.getDataList();
    },
    async onPageChange(e) {
      this.pageInfo.currentPage = e;
      this.getDataList();
    },
  },
};
</script>

<style scoped></style>
